<template>
  <div class="bg-white md:bg-gray-100 flex justify-center min-h-screen py-20">

    <div class="w-full md:w-8/10 lg:w-4/10 xl:w-3/10">
      <loader v-if="tokenUser.loading" />
      <div class="bg-white card md:shadow" v-else-if="getFormError(tokenUser)">
        <img :src="assets.icons.lg.exclamation" alt="Unable to get user!" class="mx-auto my-10">

        <div class="alert alert-red items-start">
          <span class="alert-icon">
            <ion-icon name="alert-outline" />
          </span>
          <span>
            <div class="font-bold">An Error Occurred</div>
            <div class="font-thin" v-html="getFormError(tokenUser)"></div>
          </span>
        </div>
      </div>
      <div class="bg-white card md:shadow" v-else-if="tokenUser.data">
        <h1 class="text-lg font-medium mb-5">Hey {{ firstName }},</h1>

        <div class="alert alert-blue items-start mb-10">
          <span class="alert-icon">
            <ion-icon name="alert-outline" />
          </span>
          <span>
            <p class="font-bold">Welcome to CredPal's document uploader.</p>
            <p class="font-thin">Here you can upload and manage all necessary documents required to process your credit.</p>
          </span>
        </div>

        <form @submit.prevent="upload(govtIdForm)" class="border-b mb-10 pb-10">
          <h6 class="font-medium text-base mb-5">Government Issued ID</h6>
          <div
            class="border border-blue-200 rounded px-5 py-3 mb-8 text-sm"
            v-if="lastGovtId"
          >
            <div class="opacity-50 mb-4">Last Uploaded</div>
            <div class="flex flex-row items-center justify-between">
              <a
                :href="lastGovtId.url"
                target="_blank"
                class="underline mr-3"
                >{{ lastGovtId.filename }}</a
              >
            </div>
          </div>
          <dropzone
            v-model="govtIdForm.data.govt_id.value"
            accept=".png, .jpg, .jpeg"
          />
          <div class="flex items-center -mt-8">
            <div class="text-xs text-orange-700 mr-3">
              <span
                v-if="getFirstError('govt_id', govtIdForm)"
                v-html="getFirstError('govt_id', govtIdForm)"
              />
              <span v-else>* PNG, JPG, JPEG only!</span>
            </div>
            <button
              type="submit"
              class="btn btn-blue btn-sm ml-auto"
              :disabled="govtIdForm.loading"
            >
              <sm-loader class="sm-loader-white" v-if="govtIdForm.loading" />
              <span v-else>Upload</span>
            </button>
          </div>
        </form>

        <form @submit.prevent="upload(workIdForm)" class="border-b mb-10 pb-10">
          <h6 class="font-medium text-base mb-5">Work/Staff ID</h6>
          <div
            class="border border-blue-200 rounded px-5 py-3 mb-8 text-sm"
            v-if="lastWorkId"
          >
            <div class="opacity-50 mb-4">Last Uploaded</div>
            <div class="flex flex-row items-center justify-between">
              <a
                :href="lastWorkId.url"
                target="_blank"
                class="underline mr-3"
                >{{ lastWorkId.filename }}</a
              >
            </div>
          </div>
          <dropzone
            v-model="workIdForm.data.work_id.value"
            accept=".png, .jpg, .jpeg"
          />
          <div class="flex items-center -mt-8">
            <div class="text-xs text-orange-700 mr-3">
              <span
                v-if="getFirstError('work_id', workIdForm)"
                v-html="getFirstError('work_id', workIdForm)"
              />
              <span v-else>* PNG, JPG, JPEG only!</span>
            </div>
            <button
              type="submit"
              class="btn btn-blue btn-sm ml-auto"
              :disabled="workIdForm.loading"
            >
              <sm-loader class="sm-loader-white" v-if="workIdForm.loading" />
              <span v-else>Upload</span>
            </button>
          </div>
        </form>

        <form @submit.prevent="upload(bankStatementForm)" class="mb-10 pb-10">
          <h6 class="font-medium text-base mb-5">Bank Statement</h6>
          <div
            class="border border-blue-200 rounded px-5 py-3 mb-8 text-sm"
            v-if="lastBankStatement"
          >
            <div class="opacity-50 mb-4">Last Uploaded</div>
            <div class="flex flex-row items-center justify-between">
              <a
                :href="lastBankStatement.url"
                target="_blank"
                class="underline mr-3"
                >{{ lastBankStatement.filename }}</a
              >
            </div>
          </div>
          <dropzone
            v-model="bankStatementForm.data.bank_statement.value"
            accept=".pdf"
          />
          <div class="flex items-center -mt-8">
            <div class="text-xs text-orange-700 mr-3">
              <span
                v-if="getFirstError('bank_statement', bankStatementForm)"
                v-html="getFirstError('bank_statement', bankStatementForm)"
              />
              <span v-else>* PDF only!</span>
            </div>
            <button
              type="submit"
              class="btn btn-blue btn-sm ml-auto"
              :disabled="bankStatementForm.loading"
            >
              <sm-loader class="sm-loader-white" v-if="bankStatementForm.loading" />
              <span v-else>Upload</span>
            </button>
          </div>
        </form>

        <div v-if="allDocumentsUploaded" class="text-center">
          <router-link
            :to="{ name: 'login' }"
            type="button"
            class="btn btn-blue"
            :disabled="uploading"
          >
            Go to Dashboard
          </router-link>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      tokenUser: this.$options.resource(null),
      bankStatementForm: this.$options.basicForm([
        { name: 'bank_statement', value: null }
      ], { requestName: 'bankStatement' }),
      govtIdForm: this.$options.basicForm([
        { name: 'govt_id', value: null }
      ], { requestName: 'govtId' }),
      workIdForm: this.$options.basicForm([
        { name: 'work_id', value: null }
      ], { requestName: 'workId' })
    }
  },
  computed: {
    allDocumentsUploaded() {
      return this.lastBankStatement && this.lastGovtId && this.lastWorkId
    },
    documents() {
      return this.tokenUser.data?.documents
    },
    firstName () {
      return this.tokenUser.data?.name;
    },
    lastBankStatement() {
      return this.documents?.find(doc => doc.type.match(/bank_statement/i));
    },
    lastGovtId() {
      return this.documents?.find(doc => doc.type.match(/govt_id/i));
    },
    lastWorkId() {
      return this.documents?.find(doc => doc.type.match(/work_id/i));
    },
    name () {
      return `${this.tokenUser.data?.name} ${this.tokenUser.data?.last_name}`;
    },
    token() {
      return this.$route.params.token;
    },
    uploading() {
      return this.bankStatementForm.loading || this.govtIdForm.loading || this.workIdForm.loading;
    }
  },
  mounted() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.tokenUser.setLoading();
      await this.sendRequest('authentication.loginWithAuthToken', {
        data: {
          token: this.token
        },
        success: response => {
          this.tokenUser.setValue(response.data.user);
        },
        error: error => {
          this.tokenUser.error = error;
        }
      })
      this.tokenUser.setLoading(false);
    },
    async upload(form) {
      if (!this.validateForm(form)) {
        return false
      }

      const data = new FormData;

      for (const key in form.data) {
        data.append(key, form.data[key].value);
      }

      form.error = null;
      form.success = false;
      form.loading = true;
      await this.sendRequest(`personal.documents.${form.requestName}`, this.tokenUser.data?.id, {
        data,
        success: (response) => {
          form.success = true;
          if (this.tokenUser.data?.documents) {
            this.tokenUser.data.documents.push(response.data.document)
          }

          for (const key in form.data) {
            form.data[key].value = null
          }
        },
        error: (error) => {
          form.error = error;
        }
      })
      form.loading = false;
    }
  }
}
</script>
